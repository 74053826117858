/* Sticky footer styles
-------------------------------------------------- */
html {
    position: relative;
    min-height: 100%;
    font-size: 0.875rem;
}

body {
    /* Margin bottom by footer height */
    margin-bottom: 400px;
}

#root{
    background-color: white;
}

.app-background {
    background-color: #242424;
}

/*====== HEADER =======*/
header {
    background-color: #00adda;
    color: white;
}

header a {
    color: white;
}

header .container-fluid {
    padding: 0;
}

.cbelogo {
    height: 30px;
    width: auto;
}

.cbelogo.icon {
    height: 48px;
}

a.applogo {
    margin-right: 0;
}

.menu-text {
    padding-left: 0.5rem;
}

.nav-item {
    color: #00adda;
}

.nav-item:hover {
    color: black;
    background-color: lightgrey;
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light > .nav-roles {
    color: rgb(0, 73, 135);
}

div.navbar-collapse div.nav-item:not(:first-child) {
    margin-left: 1.5rem;
}

div.navbar-collapse.show {
    margin-left: 1rem;
}

div.navbar-collapse.show div.nav-item {
    margin: 0.2rem 0.2rem 0rem 0.2rem;
    padding: 0rem 1rem 0rem 0rem;
    width: fit-content;
}

.navbar-nav > button {
    width: fit-content;
    margin-left: 0.75rem;
}

.navbar-light .navbar-brand {
    color: rgb(0, 73, 135);
}

.dropdown-item {
    padding-left: 10px;
}

.dropdown-item:hover {
    background-color: #999;
    color: white;
}

label.control-label {
    font-weight: bold;
}

/*====== FOOTER =======*/

footer {
    padding-top: 20px;
    background-color: #242424;
    color: white;
    position: relative;
    bottom: 0;
    width: 100%;
    max-height: 400px;
}

footer a {
    color: white;
    text-decoration: underline;
}

footer h4 {
    color: #00adda
}

.form-label {
    margin-bottom: unset;
}

.breadcrumb {
    background-color: white;
}

main {
    padding: 0 10px 30px 10px;    
    min-height: 450px;
}

.modal-backdrop {
    color: lightgray;
    opacity: 0.8;
}

.cbe-text-muted {
    color: #949494
}

.table th, .table td {
    padding: 0.5rem;
}

tr.dt-select-row:hover {
    cursor: pointer;
    background-color: lightgray !important;
}

tr.dt-select-row-not-first:hover {
    background-color: lightgray !important;
}

tr.dt-select-row-not-first td:not(:first-child):hover {
    cursor: pointer;
}

.LockOff {
    display: none;
    visibility: hidden;
}

.LockOn {
    display: block;
    visibility: visible;
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    width: 105%;
    height: 105%;
    background-color: #eee;
    text-align: center;
    padding-top: 20%;
    filter: alpha(opacity=50);
    opacity: 0.50;
}

.centerContent select {
    width: auto;
    margin: auto;
}

th.centerContent select {
    display: inline-block;
    vertical-align: middle;
    margin-left: 7.857rem;
}

.no-info {
    padding-left: 3em;
    font-weight: 500;
    color: red;
}

.importOptionsInfo {
    padding-bottom: 1rem;
}

.importOptionsInfo div:first-child {
    font-weight: 500;
    color: #333;
}

td.hasCheckbox {
    width: 10rem;
}

td.hasCheckbox {
    padding-right: 30px;
}

.table > tbody > tr > td {
    vertical-align: middle;
}

.table > tbody > tr > td input[type='checkbox'] {
    margin-top: 0.5rem;
}

table.quickEdit tbody {
    height: 300px;
    overflow-y: scroll;
}

/*Sliding right side panel*/
.modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.modal.right.fade .modal-dialog {
    right: -100%;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
    overflow-y: initial !important
}

.modal.right.fade.show .modal-dialog {
    right: 0;
}

.modal.right .modal-body {
    max-height: calc(100vh - 30px);
    overflow-y: auto;
}

.modal.right .modal-body .card-body {
    padding: 0.75rem;
}

.modal.right .modal-body .card-header {
    padding: 0 0.75rem 0.3rem 0.75rem;
}

.modal.right .modal-body .darkHeader {
    background-color: #666;
    color: #fff;
}

.modal.right .modal-body .card-header h5 {
    font-size: 1.2rem;
}

div.DTFC_LeftHeadWrapper,
div.DTFC_LeftBodyWrapper {
    border-right: 2px solid darkgray;
}

.tbl-col-xxsm {
    min-width: 30px;
}

.tbl-col-xsm {
    min-width: 40px;
}

.tbl-col-sm {
    min-width: 60px;
}

.tbl-col-md {
    min-width: 100px;
}

.tbl-col-lg {
    min-width: 150px;
}

.tbl-col-xlg {
    min-width: 200px;
}

.fa-table-cell {
    font-size: 1.3em;
}

.item-metadata {
    font-size: smaller;
    padding: 0.9rem;
}

/*Popover*/
.template {
    display: none;
}

.popover-body p {
    white-space: pre-line;
}

/*Table Legend*/
.legend {
    font-size: smaller;
    padding: 0.9rem;
}

.legend div {
    border: 1px solid #ddd;
    width: auto;
    padding: 5px;
    margin-bottom: 5px;
}

.bg-green {
    background-color: #ccffcc !important;
}

.bg-yellow {
    background-color: #faffbd !important;
}

.color-blue {
    color: #285cfa;
}

.color-red {
    color: red;
}

.color-confidential {
    color: #990033;
}

.color-preferred {
    color: #F28726;
}

.control-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5;
}

option:disabled {
    color: white;
    background-color: grey;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

sup {
    color: red;
}

.bg-qac {
    background-color: #64308F;
}

.bg-dev {
    background-color: #666;
}

.bg-uat {
    background-color: #008B46;
}

.bg-live {
    background-color: #00adda;
}

.breadcrumb-item + .breadcrumb-item::before {
    content: ">";
}

.breadcrumb-item + .breadcrumb-item {
    padding-left: 0.7rem;
}

.breadcrumb-item {
    color: #333;
    font-size: 1rem;
    font-weight: 500;
}

.breadcrumb {
    padding: 0.75rem 0rem 0.75rem 0rem !important;
}

.card-header h5 {
    line-height: 2.0625rem;
    font-size: 1.4rem;
    margin-bottom: 0;
    margin-top: 0.1rem;
}

.card-header h5 img {
    margin-bottom: 0.3rem;
}

.card.home .a-summary {
    margin-top: 0.3rem;
    margin-bottom: 1.3rem;
}

select.form-control:disabled:focus {
    outline: none;
    box-shadow: none;
}

select.form-control:disabled {
    -webkit-appearance: none;
    -moz-appearance: none;
}

.alert.alert-info {
    background-color: #e9f5f9;
}

.table tbody tr.dt-select-row:nth-of-type(odd):hover {
    background-color: lightgray;
}

.table thead tr th {
    background-color: #666;
    color: #fff;
}

.formCardHeader {
    font-weight: 500;
    padding: 1rem;
    background-color: #e9f5f9;
    margin: 20px -17px 20px -18px;
    border-top: #ddd 1px solid;
    border-bottom: #ddd 1px solid;
}

.formCardHeader.top {
    margin-top: -18px;
}

.readMore p {
    white-space: pre-line;
    overflow: hidden;
    margin: 0;
}

.toggle, .toggle-on, .toggle-off {
    border-radius: 14px;
}


.toggle-handle.btn-light {
    border-color: #dae0e5;
}

.fa-spinner {
    color: #00adda;
}

.spinner-text {
    color: #333;
    font-weight: 500;
    font-size: 1.5rem;
    margin-top: -15px;
    padding-left: 15px;
}

.hidden {
    display: none;
}

div.colorDiv {
    position: relative;
    padding: 1rem;
    margin: 1rem -.75rem 0;
}

.form-control-color {
    width: 6rem;
    height: 3rem;
    padding: .375rem;
}

/* USED FOR THE ORDERING INTERFACE */
ol {
    list-style: decimal;
    padding: 0;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

ol.example {
    border: 1px solid #999999;
    cursor: pointer;
}

ol.vertical {
    margin: 0 0 9px 0;
}

ol.vertical li {
    display: block;
    margin: 5px;
    padding: 5px;
    border: 1px solid #cccccc;
    color: #0088cc;
    background: #eeeeee;
}

li {
    line-height: 18px;
}

body.dragging, body.dragging * {
    cursor: move !important;
}

.dragged {
    position: absolute;
    opacity: 0.5;
    z-index: 2000;
}

ol.example li.placeholder {
    position: relative;
}

ol.example li.placeholder:before {
    position: absolute;
}

.dropzone-card {
    width: 16rem;
    margin: 20px;
}

#dtTile tr.dt-select-row:hover {
    cursor: pointer;
    background-color: lightgray;
}

#myDropzone {
    background: white;
    border: 2px dashed #0087F7;
    border-radius: 5px;
    text-align: center;
    min-height: 0 !important;
    padding: 0 !important;
}

.image-view-card {
    margin: 20px;
    background-color: lightgray;
}

#sampleCard {
    width: 16rem;
    padding: 20px;
}

/* Placeholder Formatting */

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: grey !important;
    font-style: italic;
}

::-moz-placeholder { /* Firefox 19+ */
    color: grey !important;
    font-style: italic;
}

:-ms-input-placeholder { /* IE 10+ */
    color: grey !important;
    font-style: italic;
}

:-moz-placeholder { /* Firefox 18- */
    color: grey !important;
    font-style: italic;
}

.col-contact-icon {
    width: 4rem !important;
}

.col-confidential-icon {
    width: 2rem !important;
}


.btn {
    margin: 0 5px;
}

.homeInfoTable {
    width: 100%;
    font-weight: bold;
    text-align: center;
    border-collapse: separate;
    border: solid black 1px;
    border-radius: 6px;
}

.error-message {
    color: red;
}

.textAlign-end {
    text-align: end
}

.textAlign-end .col {
    padding-right: 0;
}

.textAlign-end div {
    padding-right: 0;
}

.HomeTable th, .HomeTable td {
    padding: 15px;
    text-align: center;
}

.ActiveHomeTable tr:hover {
    background-color: coral;
}

.DueInWeekHomeTable tr:hover {
    background-color: cornflowerblue;
}

.form-label {
    font-weight: bold;
}

.required {
    border-left: 5px #F00 solid !important;
    padding-left: 0.5em !important;
}

.requiredDate {
    border-left: 5px #F00 solid !important;
}

.MuiTablePagination-displayedRows {
    margin-bottom: 0 !important;
}

.MuiTablePagination-selectLabel {
    margin-bottom: 0 !important;
}

.gridHeadTr, .MuiDataGrid-scrollbarFiller--header {
    background-color: #8f8d8d;
    color: #fff;
    font-weight: bolder;
    font-size: larger;
}

.gridHeadTr button {
    color: #fff;
}

.MuiInputBase-input {
    padding: 0.375rem 0.75rem !important;
    border: 1px solid #ced4da !important;
}
.MuiTablePagination-select{
    padding-right: 24px !important;
}

.MuiFormControl-root form-control required {
    padding-left: 0 !important;
}

.tableExceptionDescriptionCell {
    text-align: left !important;
    padding-left: 10px !important;
    width: 70%;
}

.card.same-height {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.swal2-styled.swal2-confirm {
    background-color: #0d6efd !important;
}

.swal2-styled.swal2-confirm:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5) !important;
}
.MuiDataGrid-columnHeaders{
    background-color: #8f8d8d; 
}

span.checkbox.readonly svg {
    opacity: 0.7;
}

.show-more-less-clickable
{
    color: rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1));
    text-decoration: underline;
    cursor: pointer;
}

.label{ 
    margin-right: 10px; 
}

.form-control-plaintext{
    padding: 0 0 0.375rem 0 !important;
}

.remove-margin-bottom {
    margin-bottom: 0 !important;
}

.hint{
    font-weight: normal;
    font-size: small; 
    font-style: italic;
    color: grey;
}

.recharts-wrapper *:focus {
    outline: none;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .cbelogo {
        height: 45px;
    }

    .nav-item {
        background-color: inherit;
    }

    .navbar-nav .nav-link {
        padding-left: 0;
    }
}

/* Small devices */
@media only screen and (max-width: 1024px) {
    .navbar-collapse li {
        background-color: #f7f7f7;
        padding-left: 1rem;
    }

    th.centerContent select {
        margin-left: 0;
    }
}